import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { computed, makeObservable } from 'mobx';
import { stores } from 'state/stores';
import API from 'utils/API';
import { getRelativeDate } from 'utils/misc';
import { WorkoutCellInnerProps } from './types';
import {
  calculateCommentCount,
  calculateLikeCount,
  calculateWorkoutContent,
  calculateWorkoutStats,
} from './utils';
import { sendEvent } from 'utils/events';
import { copyToClipboard } from 'utils/clipboard';
import { Workout } from 'hevy-shared';

export class WorkoutCellViewModel {
  workout: Workout;

  constructor(workout: Workout) {
    makeObservable(this, {
      data: computed,
    });

    this.workout = workout;
  }

  onLikeWorkout = async () => {
    await stores.workouts.likeWorkout(this.workout.id);
    stores.workouts.fetchWorkout(this.workout.id);
  };

  onCommentSend = async (comment: string) => {
    if (comment.length === 0) return;

    await API.postWorkoutComment(this.workout.id, comment);
    stores.workouts.fetchWorkout(this.workout.id);
  };

  onCommentDelete = async (commentId: number) => {
    await API.deleteWorkoutComment(commentId);
    stores.workouts.fetchWorkout(this.workout.id);
  };

  get data(): WorkoutCellInnerProps {
    const w = this.workout;

    return {
      workout: w,
      key: w.id,
      workoutId: w.id,
      shortId: w.short_id ?? undefined,
      title: w.name,
      description: w.description,
      username: w.username,
      date: getRelativeDate(dayjs.unix(w.end_time)),
      profileImage: w.profile_image,
      currentUserImage: stores.account.profile_pic,
      stats: calculateWorkoutStats(w, stores.userPreferences),
      content: calculateWorkoutContent(w),
      onShare: () => {
        sendEvent('workoutCell_sharePress');
        copyToClipboard(`https://${location.hostname}/workout/${w.id}`);
        toast.success('Copied link to clipboard');
      },
      onCommentSend: this.onCommentSend,
      commentCount: calculateCommentCount(w),
      comments: stores.workouts.workouts[this.workout.id]?.comments || [],
      likeCount: calculateLikeCount(w),
      isLiked: w.is_liked_by_user,
      likeImages: w.like_images || [],
      onLike: this.onLikeWorkout,
      onCommentDelete: this.onCommentDelete,
    };
  }
}
