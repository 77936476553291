import React from 'react';
import { ExerciseImage } from 'components/ExerciseImage';
import styled from 'styled-components';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { getSupersetColor } from 'utils/misc';
import { ExerciseProps } from '../types';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { View } from 'styleguide/View';
import { FlexRow } from 'styleguide/Row';

const Container = styled(View)`
  gap: ${Spacing.md}px;
`;

const ExercisesContainer = styled(View)`
  gap: ${Spacing.md}px;
`;

const TruncatedTextTitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

interface ExercisesProps {
  exercises: ExerciseProps[];
  truncatedExercisesTitle?: string;
}

export const Exercises = ({ exercises, truncatedExercisesTitle }: ExercisesProps) => {
  return (
    <Container>
      <TextSMRegular>Workout</TextSMRegular>
      <ExercisesContainer>
        {exercises.map(e => (
          <Exercise key={e.key} superset={e.superset} image={e.image} title={e.title} />
        ))}
      </ExercisesContainer>
      {truncatedExercisesTitle && (
        <TruncatedTextTitleContainer>
          <TextSMRegular>{truncatedExercisesTitle}</TextSMRegular>
        </TruncatedTextTitleContainer>
      )}
    </Container>
  );
};

const Exercise = ({ superset, image, title }: ExerciseProps) => {
  return (
    <FlexRow>
      {superset !== undefined && <SupersetSliver superset={superset} />}
      <ExerciseImage source={image} diameter={48} />
      <TextSMMedium style={{ marginLeft: Spacing.sm }}>{title}</TextSMMedium>
    </FlexRow>
  );
};

interface SupersetSliverProps {
  superset: number;
}

const SupersetSliver = ({ superset }: SupersetSliverProps) => (
  <div
    style={{
      display: 'flex',
      backgroundColor: getSupersetColor(superset),
      width: Spacing.xs,
      height: 48,
      borderRadius: CornerRadius.xs,
      marginRight: Spacing.sm,
    }}
  />
);
