import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { TextXSRegular } from 'styleguide/Texts';
import { TinyOverlappingProfileImages } from './WorkoutCell/components/TinyOverlappingProfileImages';
import { Spacing } from 'styleguide/spacing';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

const LikeCountContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface WorkoutSocialPreviewProps {
  likeCount: string;
  commentCount: string;
  likeImages: string[];
  style?: CSSProperties;
  onLikesClick?: () => void;
  onCommentsClick?: () => void;
}

export const WorkoutSocialPreview = ({
  likeCount,
  likeImages,
  commentCount,
  style,
  onLikesClick,
  onCommentsClick,
}: WorkoutSocialPreviewProps) => {
  return (
    <Container style={style}>
      <LikeCountContainer
        style={{ cursor: onLikesClick ? 'pointer' : 'default' }}
        onClick={onLikesClick}
      >
        {!!likeImages.length && (
          <TinyOverlappingProfileImages style={{ marginRight: Spacing.sm }} images={likeImages} />
        )}
        <TextXSRegular>{likeCount}</TextXSRegular>
      </LikeCountContainer>
      <TextXSRegular
        style={{ cursor: onCommentsClick ? 'pointer' : 'default' }}
        onClick={onCommentsClick}
      >
        {commentCount}
      </TextXSRegular>
    </Container>
  );
};
