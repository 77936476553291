import { ProfileImage } from 'components/ProfileImage';
import React, { CSSProperties } from 'react';
import { colors } from 'styleguide/colors';
import { FlexRow } from 'styleguide/Row';

interface TinyOverlappingProfileImagesProps {
  images: string[];
  style?: CSSProperties;
}

export const TinyOverlappingProfileImages = ({
  images,
  style,
}: TinyOverlappingProfileImagesProps) => {
  return (
    <FlexRow style={style}>
      {images.map((i, index) => (
        <ProfileImage
          key={i}
          style={{
            marginRight: images.length - 1 === index ? 0 : -5,
            border: `1px solid ${colors.white}`,
          }}
          source={i}
          diameter={20}
        />
      ))}
    </FlexRow>
  );
};
