import React from 'react';
import styled, { ThemeProps } from 'styled-components';
import { Card } from 'styleguide/Card';
import { colors } from 'styleguide/colors';
import { Theme } from 'styleguide/types';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 16px;
`;

const TopContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const GreyBox = styled.div`
  background-color: ${(props: ThemeProps<Theme>) =>
    props.theme.name === 'light' ? colors.neutral100 : colors.neutral700};
`;

export const LoadingCell = () => {
  return (
    <Container>
      <TopContainer>
        <GreyBox style={{ height: 50, width: 50, marginRight: 16, borderRadius: 25 }}>
          <div />
        </GreyBox>
        <GreyBox style={{ height: 30, width: '20%' }}>
          <div />
        </GreyBox>
      </TopContainer>
      <GreyBox style={{ height: 30, width: '50%', marginBottom: 32 }}>
        <div />
      </GreyBox>
      <GreyBox style={{ height: 160, width: '100%', marginBottom: 32 }}>
        <div />
      </GreyBox>
      <BottomContainer>
        <GreyBox style={{ height: 30, width: '20%' }}>
          <div />
        </GreyBox>
        <GreyBox style={{ height: 30, width: '20%' }}>
          <div />
        </GreyBox>
        <GreyBox style={{ height: 30, width: '20%' }}>
          <div />
        </GreyBox>
      </BottomContainer>
    </Container>
  );
};
