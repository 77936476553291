import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import { stores } from 'state/stores';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton, TertiaryButton } from 'styleguide/Buttons';

const Container = styled.div``;

interface FollowButtonProps {
  username: string;
  style?: CSSProperties;
  buttonStyle?: CSSProperties;
}

export const FollowButton = observer(({ username, style, buttonStyle }: FollowButtonProps) => {
  const status = stores.followingStatus.status(username);

  const title = (() => {
    switch (status) {
      case 'following':
        return 'Unfollow';
      case 'not-following':
        return 'Follow';
      case 'requested':
        return 'Pending';
    }
  })();

  return (
    <Container style={style}>
      {stores.account.username !== username && (
        <TertiaryButton
          style={buttonStyle}
          onClick={event => {
            // Allows the follow button to sit in a Link without triggering the link onClick
            event.preventDefault();

            if (status === 'following') {
              stores.followingStatus.unfollow(username);
            } else if (status === 'not-following') {
              stores.followingStatus.follow(username);
            }
          }}
        >
          {title}
        </TertiaryButton>
      )}
    </Container>
  );
});

export const FollowButtonPrimary = observer(
  ({ username, style, buttonStyle }: FollowButtonProps) => {
    const status = stores.followingStatus.status(username);

    const title = (() => {
      switch (status) {
        case 'following':
          return 'Unfollow';
        case 'not-following':
          return 'Follow';
        case 'requested':
          return 'Pending';
      }
    })();

    const Button = status === 'not-following' ? PrimaryButton : SecondaryButton;

    return (
      <Container style={style}>
        {stores.account.username !== username && (
          <Button
            style={buttonStyle}
            title={title}
            onClick={() => {
              if (status === 'following') {
                stores.followingStatus.unfollow(username);
              } else if (status === 'not-following') {
                stores.followingStatus.follow(username);
              }
            }}
          />
        )}
      </Container>
    );
  },
);
