import React, { useState } from 'react';
import styled from 'styled-components';
import { WorkoutContentProps } from '../types';
import { Exercises } from './Exercises';
import LeftArrowSvg from './assets/image-arrow-left.svg';
import RightArrowSvg from './assets/image-arrow-right.svg';
import { CornerRadius, Spacing } from 'styleguide/spacing';

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExerciseContainer = styled.div`
  position: relative;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: ${CornerRadius.sm}px;
`;

const ArrowContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const LeftArrowContainer = styled.div`
  margin-left: ${Spacing.md}px;
  cursor: pointer;
`;

const RightArrowContainer = styled.div`
  margin-right: ${Spacing.md}px;
  cursor: pointer;
`;

export const WorkoutContent = ({
  images,
  exercises,
  truncatedExercisesTitle,
  publicWorkout,
}: WorkoutContentProps) => {
  const [contentIndex, setContentIndex] = useState(0);

  if (images.length === 0 || publicWorkout) {
    return (
      <ExerciseContainer>
        <Exercises exercises={exercises} truncatedExercisesTitle={truncatedExercisesTitle} />
      </ExerciseContainer>
    );
  }

  return (
    <ImageContainer>
      <ArrowContainer>
        {contentIndex > 0 ? (
          <LeftArrowContainer
            onClick={e => {
              e.preventDefault();
              setContentIndex(contentIndex - 1);
            }}
          >
            <LeftArrowSvg />
          </LeftArrowContainer>
        ) : (
          <div />
        )}
        {contentIndex < images.length - 1 ? (
          <RightArrowContainer
            onClick={e => {
              e.preventDefault();
              setContentIndex(contentIndex + 1);
            }}
          >
            <RightArrowSvg />
          </RightArrowContainer>
        ) : (
          <div />
        )}
      </ArrowContainer>
      <StyledImage src={images[contentIndex]} />
    </ImageContainer>
  );
};
