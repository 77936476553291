import React from 'react';
import styled from 'styled-components';
import { Spacing } from 'styleguide/spacing';
import { TextSMMedium, TextXSRegular } from 'styleguide/Texts';

const Container = styled.div<{ layout?: 'horizontal' | 'vertical' }>`
  display: flex;
  flex-direction: ${({ layout }) => (layout === 'horizontal' ? 'row' : 'column')};
  align-items: center;
  padding-right: ${Spacing.md}px;
  gap: ${Spacing.xs}px;
`;

interface ProfileStatProps {
  label: string;
  count: string;
  layout?: 'horizontal' | 'vertical';
  onClick?: () => void;
}

export const ProfileStat = ({ label, count, layout, onClick }: ProfileStatProps) => {
  return (
    <Container
      onClick={onClick}
      style={{ cursor: !!onClick ? 'pointer' : 'default' }}
      layout={layout}
    >
      <TextXSRegular>{label}</TextXSRegular>
      <TextSMMedium>{count}</TextSMMedium>
    </Container>
  );
};
